import React, { useState } from 'react';
import PairList from './PairList';

const PairPage = ( { pairs } ) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Number of pairs to display per page
  const totalPages = Math.ceil(pairs.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <PairList pairList={pairs} currentPage={currentPage} itemsPerPage={itemsPerPage} />
      <div className="pagination">
        {Array.from({ length: totalPages }).map((_, index) => (
          <button key={index + 1} onClick={() => handlePageChange(index + 1)}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PairPage;
