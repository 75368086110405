// NetworkInfo.js
import React from 'react';
import './NetworkInfo.css';  // Import the CSS file for styling

const NetworkInfo = ({ network, selectedId}) => {
  const isSelected = selectedId === network.id;

  return (
    <div className={`network-info ${isSelected ? 'selected' : ''}`}>
      <img className="network-logo" src={network.logo} alt={network.name} />
      <a href={network.explorer} target="_blank" rel="noopener noreferrer">
        <p>{network.name}</p>
      </a>
    </div>
  );
};

export default NetworkInfo;
