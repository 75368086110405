import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

import Nav from '../nav/Nav';
import Footer from '../footer/Footer';
import Home from '../home/Home';
import About from '../about/About';
import Pairs from '../pairs/Pairs';
import AdvancedChartsPairs from '../components/charts/AdvancedCharts';

function App() {
	return (
		<div>
			<BrowserRouter>
				<Nav />
				<Routes>
					<Route path="" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/pairs" element={<Pairs />} />
					<Route path="/spot" element={<AdvancedChartsPairs />} />
					<Route path="/spot/:ticker?" element={<AdvancedChartsPairs />} />
					{/* Other routes */}
				</Routes>
				<Footer />
			</BrowserRouter>
		</div>
	)
}

export default App