import './BarStat.css'

import React from 'react';
import defaultNetworkImage from '../../static/network.svg'

const BarStat = ({ network, dex }) => {
    
    console.log("BarStat network", network);
    console.log("BarStat dex", dex);

    
    const formatImage = (imageUrl) => {
        return imageUrl ? imageUrl : defaultNetworkImage;
    };


    return (
        <div className={'MainBar'}>
            <div className="network-details">
                <div className="network-logo">
                    <img src={formatImage(network?.logo)} alt={network?.name} style={{ width: '50px', height: '50px' }} />
                </div>
                <div className="network-info">
                    <div>Network</div>
                    <div>Name {network?.name}</div>
                </div>
            </div>

            <div className="dex-details">
                <div className="dex-logo">
                    <img src={formatImage(dex?.logo)} alt={dex?.symbol} style={{ width: '50px', height: '50px' }} />
                </div>
                <div className="dex-info">
                    <div>DEX</div>
                    <div>Name {dex?.name}</div>
                </div>
            </div>
        </div>
    );
};

export default BarStat;