import './AdvancedCharts.css'

import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import datafeed from '../datafeed';
import { fetchPairTxns } from '../../api/onchain_api';

import { widget } from '../../charting_library'
import LoadingSpinner from '../loading/LoadingSpinner';
import TransactionList from '../transactions/TransactionList';
import BarStat from '../globalStat/BarStat';
import BarStatLoading from '../globalStat/BarStatLoading';
import PairDetails from '../pairStat/PairDetails';
import { fetchOnChainSinglePair, fetchOnChainNetworkID, fetchOnChainExchangeId, fetchOnChainTokenID } from '../../api/onchain_api';
import { SPOT_PAIR_ADDRESS } from '../../api/constants'


function getLanguageFromURL(url) {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(url);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

// Define loading steps
const loadingSteps = [
	'Load Pair',
	'Load Exchanges',
	'Load Networks',
	'Load Token A',
	'Load Token B',
	'Load Pair Transaction',
	'Load Pair Audit'
];

const STEP_FINISH = loadingSteps.length

const failResult = "Query for Data failed"

// Function to get loading text
function getLoadingText(step) {
	if (step == 99) return failResult;

	if (step < 1 || step > loadingSteps.length) {
		return 'Invalid loading step';
	}

	return loadingSteps[step - 1];  // Adjust the index to start from 0
}

// Usage within your component
const currentURL = window.location.href; // Safely obtain the URL
const language = getLanguageFromURL(currentURL) || 'en';

export const AdvancedCharts = () => {
	const chartContainerRef = useRef();
	const { ticker } = useParams();
	const [pair, setPair] = useState(null);
	const [network, setNetwork] = useState(null);
	const [exchange, setExchange] = useState(null);
	const [tokenA, setTokenA] = useState(null);
	const [tokenB, setTokenB] = useState(null);
	const [transactions, setTransactions] = useState([]);
	const [loadingStep, setLoadingStep] = useState(0);

	const defaultProps = {
		symbol: ticker || SPOT_PAIR_ADDRESS,
		interval: '240',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'onchainvision.xyz',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};

	useEffect( () => {

		const queryAPIData = async () => {
			// Remove the _r suffix if it exists
			const defaultSymbol = defaultProps.symbol;
			const symbol = defaultSymbol.endsWith('_r') ? defaultSymbol.slice(0, -2) : defaultSymbol;

			try {
				setLoadingStep(1);
				const retPair = await fetchOnChainSinglePair(symbol);
				if (!retPair) {
					throw new Error("Error: retPair is undefined");
				}
				setPair(retPair);
		
				setLoadingStep(2);
				const my_exchange = await fetchOnChainExchangeId(retPair.exchange);
				if (!my_exchange) {
					throw new Error("Error: my_exchange is undefined");
				}
				setExchange(my_exchange);
		
				setLoadingStep(3);
				const ret_networks = await fetchOnChainNetworkID(my_exchange.network);
				if (!ret_networks) {
					throw new Error("Error: ret_networks is undefined");
				}
				setNetwork(ret_networks);
		
				setLoadingStep(4);
				const my_token_A = await fetchOnChainTokenID(retPair.tokenA);
				if (!my_token_A) {
					throw new Error("Error: my_token_A is undefined");
				}
				setTokenA(my_token_A);
		
				setLoadingStep(5);
				const my_token_B = await fetchOnChainTokenID(retPair.tokenB);
				if (!my_token_B) {
					throw new Error("Error: my_token_B is undefined");
				}
				setTokenB(my_token_B);
		
				setLoadingStep(6);
				const transactions = await fetchPairTxns(symbol);
				if (!transactions) {
					throw new Error("Error: transactions is undefined");
				}
				setTransactions(transactions);
				setLoadingStep(7);
			} catch (error) {
				console.error('Error handling network change:', error);
				setLoadingStep(99);
			}
		};
		queryAPIData();

		const widgetOptions = {
			symbol: defaultProps.symbol,
			datafeed: datafeed,
			interval: defaultProps.interval,
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath,

			locale: language,
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
		};

		console.log('widgetOptions:', widgetOptions);
		const tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {

			// Add volume indicator
			tvWidget.chart().createStudy('volume', true);

			tvWidget.headerReady().then(() => {



				const button = tvWidget.createButton();
				button.setAttribute('OnChainVision', 'OnChainVision API Health');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'OnChainVision Health',
					body: 'OnChain Vision API is online',
					callback: () => {
						console.log('Noticed!');
					},
				}));

				button.innerHTML = 'OnChainVision API';
			});
		});

		return () => {
			tvWidget.remove();
		};
	}, [ticker]);

	return (
		<div className={'ChartContainer'}>
			<div className={'BarStat'}>
				{/* <BarStat /> */}
				{loadingStep != STEP_FINISH ? 
					<BarStatLoading loadingStep={loadingStep} loadingText={getLoadingText(loadingStep)} /> 
					: 
					<BarStat network={network} dex={exchange} />}
			</div>
			
			<div className={'RowContainer'}>
					{window.innerWidth > 768 && (
					<div className={'PairStat'}>
						{loadingStep != 99 && loadingStep < 6 ? <LoadingSpinner /> : <PairDetails pair={pair} tokenA={tokenA} tokenB={tokenB}/>}
					</div>
					)}
					<div ref={chartContainerRef} className={'TVChartContainer'} />
				</div>

			<div className={'TransactionList'}>
				{loadingStep != 99 && loadingStep < 7 ? <LoadingSpinner /> : <TransactionList transactions={transactions} />}
			</div>
		</div>
	);
}

export default AdvancedCharts
