import './BarStat.css'

import React from 'react';

const BarStatLoading = ({ loadingStep, loadingText }) => {
    return (
        <div className={'MainBarLoading'}>
        <p>LoadingStep: {loadingStep}</p> <br />
        <p>LoadingStep Details: {loadingText}</p><br />
        </div>
    );
};

export default BarStatLoading;