// NetworkCarousel.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import NetworkInfo from './NetworkInfo';
import './NetworkCarousel.css';  // Import the CSS file for styling
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NetworkCarousel = ({ networkArray, selectedId, onSelect }) => {

  const handleItemClick = (index) => {
    onSelect(index);
  };

  const settings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    speed: 500,
    centerPadding: "60px",
    variableWidth: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: (index) => {
      // Update internalSelectedId after each change in the carousel
      const newSelectedId = networkArray[index]?.id || null;
      // Notify the parent component about the change
      onSelect(newSelectedId);
    },
  };

  return (
    <div className="network-carousel-container">
      <Slider {...settings}>
        {networkArray.map((network) => (
          <div key={network.id} onClick={() => handleItemClick(network.id)}>
            <NetworkInfo key={network.id} network={network} selectedId={selectedId} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default NetworkCarousel;
