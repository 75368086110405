import './PairDetails.css';
import React from 'react';
import defaultTokenImage from '../../static/token.svg'

const PairDetails = ({ pair, tokenA, tokenB }) => {
    console.log("PairDetails tokenA", tokenA);
    console.log("PairDetails tokenB", tokenB);

    
    const formatImage = (imageUrl) => {
        return imageUrl ? imageUrl : defaultTokenImage;
    };

    //TODO: improve
    if(!tokenA || !tokenB)
    {
        return(
            <div>Token Not defined</div>
        )
    } 

    return (
        <div className={'MainColumn'}>
            <div className="token-details">
                <div className="token-logo">
                    <img src={formatImage(tokenB?.logo)} alt={tokenB?.symbol} style={{ width: '50px', height: '50px' }} />
                </div>
                <div className="token-info">
                    <div>Base Token</div>
                    <div>Symbol {tokenB?.symbol}</div>
                    <div>Name {tokenB?.name}</div>
                    <div>Address {tokenB?.address}</div>
                    <div>Supply {tokenB?.supply}</div>
                </div>
            </div>

            <div className="token-details">
                <div className="token-logo">
                    <img src={formatImage(tokenA?.logo)} alt={tokenA?.symbol} style={{ width: '50px', height: '50px' }} />
                </div>
                <div className="token-info">
                    <div>Main Token</div>
                    <div>Symbol {tokenA?.symbol}</div>
                    <div>Name {tokenA?.name}</div>
                    <div>Address {tokenA?.address}</div>
                    <div>Supply {tokenA?.supply}</div>
                </div>
            </div>

            <div className="pair-details">
                <div className="token-info">
                    <div>Pair: {pair.address}</div>
                    <div>Liquidity:</ div> 
                    <div>{pair.tokenABalance} {tokenA?.symbol}</div>
                    <div>{pair.tokenBBalance} {tokenB?.symbol}</div>
                </div>
            </div>
        </div>
    );
};

export default PairDetails;
