// ExchangeCarousel.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import ExchangeInfo from './ExchangeInfo';
import './ExchangeCarousel.css';  // Import the CSS file for styling
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const ExchangeCarousel = ({ exchangeArray, selectedId, onSelect }) => {

  const handleItemClick = (index) => {
    if(selectedId === index)
    {
      console.log("ExchangeCarousel: selectedId === index")
      onSelect(-1);
    }
    else{
      onSelect(index);
    }
  };

  const settings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    speed: 500,
    centerPadding: "60px",
    variableWidth: true,
    slidesToShow: exchangeArray.length,
    slidesToScroll: 1,
    afterChange: (index) => {
      // Update internalSelectedId after each change in the carousel
      const newSelectedId = exchangeArray[index]?.id || null;
      // Notify the parent component about the change
      onSelect(newSelectedId);
    },
  };
  console.log("ExchangeCarousel: exchangeArray=", exchangeArray)

  return (
    <div className="exchange-carousel-container">
      <Slider {...settings}>
        {exchangeArray.map((exchange) => (
          <div key={exchange.id} onClick={() => handleItemClick(exchange.id)}>
            <ExchangeInfo key={exchange.id} exchange={exchange} selectedId={selectedId} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ExchangeCarousel;
