import { fetchPairData, fetchPairs } from "../api/onchain_api";
import { convertDateToUtcUnix, computePriceScale } from "./helper"

let returnPairDataLenght = 0


const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    // supported_resolutions: ['1h', '2h', '3h', '4h', '1D', '1W', '1M'],
    supported_resolutions: ['1', '5', '15', '30', '60', '120', '180', '240', '1D', '1W', '1M'],
    // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
    exchanges: [
        { value: 'OnChainVision', name: 'OnChainVision', desc: 'OnChainVision' },
    ],
    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    symbols_types: [
        { name: 'crypto', value: 'crypto' }
    ]
};

export default {
    onReady: (callback) => {
        console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData));
    },
    searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
        console.log('[searchSymbols]: Method call');

        try {
            const symbols = await getAllSymbols();
            const filteredSymbols = symbols.filter(pair => (pair.symbol.toLowerCase().includes(userInput.toLowerCase()) || pair.address.toLowerCase().includes(userInput.toLowerCase())));
            const newSymbols = filteredSymbols.map(pair => {
                return {
                    symbol: pair.symbol,
                    full_name: pair.symbol,
                    description: pair.address,
                    exchange: configurationData.exchanges[0].value, 
                    ticker: pair.address,
                    type: 'crypto',
                }
            }
            )

            onResultReadyCallback(newSymbols);

        } catch (error) {
            console.error('Error fetching symbols:', error);
            // Handle error if needed
        }

    },
    resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
        console.log('[resolveSymbol]: Method call', symbolName);

        try {
            const symbols = await getAllSymbols();
            console.log('[resolveSymbol]: symbolName =', symbolName);
            console.log(symbols)

            let symbolItem = symbols.find(({ address }) => address === symbolName);

            const symbolItemReverse = symbols.find(({ address }) => address + "_r" === symbolName);

            if (!symbolItem && !symbolItemReverse) {
                console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
                onResolveErrorCallback('Cannot resolve symbol');
                return;
            }

            let adjust 

            if (!symbolItem)
            {
                symbolItem = symbolItemReverse
                symbolItem.address = symbolItem.address + "_r"
                symbolItem.symbol = symbolItem.symbol.split("-")[2] + "/" + symbolItem.symbol.split("-")[1]
                let price = symbolItem.tokenABalance / symbolItem.tokenBBalance // TODO: on reverse compute price
                console.log('[resolveSymbol]: price', price);
                adjust = computePriceScale(price)
            }
            else
            {
                symbolItem.symbol = symbolItem.symbol.split("-")[1] + "/" + symbolItem.symbol.split("-")[2]
                let price = symbolItem.tokenBBalance / symbolItem.tokenABalance // TODO: on reverse compute price
                console.log('[resolveSymbol]: price', price);
                adjust = computePriceScale(price)
            }



            const pair_pricescale = adjust.finalPriceScale
            const pair_minMove = adjust.finalMinMove

            
            console.log("Computed pair_pricescale:", pair_pricescale);
            console.log("Computed pair_minMove:", pair_minMove);

            // Symbol information object
            const symbolInfo = {
                ticker: symbolItem.address,
                name: symbolItem.symbol,
                description: symbolItem.symbol,
                type: 'crypto',
                session: '24x7',
                timezone: 'Etc/UTC',
                exchange: configurationData.exchanges[0].value, //TODO: set the correct exchange,
                minmov: pair_minMove,
                pricescale: pair_pricescale,
                has_intraday: true,
                visible_plots_set: 'ohlc',
                has_weekly_and_monthly: false,
                supported_resolutions: configurationData.supported_resolutions,
                volume_precision: 2,
                data_status: 'streaming',
            };

            console.log('[resolveSymbol]: Symbol resolved', symbolName);
            onSymbolResolvedCallback(symbolInfo);


        } catch (error) {
            console.error('Error fetching symbols:', error);
        }

    },
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        console.log('[getBars]: Method call', symbolInfo);
        console.log('[getBars]: periodParams', periodParams);
        console.log('[getBars]: resolution', resolution);

        try {
            let resolutionInMinutes;
            let triggerResolution;
            if (resolution.endsWith('D')) {
                triggerResolution = resolution;
            } else {
                resolutionInMinutes = parseInt(resolution);
                if(resolutionInMinutes < 60) {
                    triggerResolution = `${resolution}m`;
                }
                else if(resolutionInMinutes === 60) {
                    triggerResolution = '1h';
                }
                else if(resolutionInMinutes === 120) {
                    triggerResolution = '2h';
                }
                else if(resolutionInMinutes === 180) {
                    triggerResolution = '3h';
                }
                else if(resolutionInMinutes === 240) {
                    triggerResolution = '4h';
                }
            }

            let reverse = false;
            let pairAddress = symbolInfo.ticker

            //If the symbolInfo has a _r at the end, it means that the reverse flag is set to true
            // and we need to reverse the pair and delete the _r from the symbol
            console.log("[getBars]: SymbolInfo:", symbolInfo)
            if(symbolInfo.ticker.endsWith('_r') || symbolInfo.ticker.endsWith("_r")) {
                reverse = true;
                pairAddress = pairAddress.slice(0, -2);
            }
    
            const bars = await fetchPairData(pairAddress, triggerResolution, reverse)

            const fromTimestamp = new Date(periodParams.from).getTime();
            const toTimestamp = new Date(periodParams.to).getTime();

            const finalBars = bars.filter(bar => {
                const barTimestamp =  Math.floor(bar.time / 1000); // Convert Unix timestamp to milliseconds
                return barTimestamp >= fromTimestamp && barTimestamp <= toTimestamp;
            });
            console.log(`[getBars]: returned ${finalBars.length} filter bar(s) out of ${bars.length} from ${periodParams.from} to ${periodParams.to}`);

            let noDataLeft = false;

            returnPairDataLenght += finalBars.lenght

            if (returnPairDataLenght >= bars.lenght)
            {
                //Reinitialize the global Variable and stop searching
                noDataLeft = true
                returnPairDataLenght = 0

            }
            onHistoryCallback(finalBars, { noData: noDataLeft });

        } catch (error) {
            console.error('Error fetching Pair Data:', error);
            onHistoryCallback([], { noData: true });
            return;
        }


    },
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
        console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
    },
    unsubscribeBars: (subscriberUID) => {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    },
};

// DatafeedConfiguration implementation
async function getAllSymbols() {
    try {
        const data = await fetchPairs(1);
        let allSymbols = [];

        for (const pair of data) {
            const symbols = {
                id: pair.id,
                exchange: pair.exchange,
                symbol: pair.symbol,
                address: pair.address,
                tokenA: pair.tokenA,
                tokenB: pair.tokenB,
                tokenABalance: pair.tokenABalance,
                tokenBBalance: pair.tokenBBalance

            };
            allSymbols.push(symbols);
        }
        return allSymbols;
    } catch (error) {
        console.error("Error fetching pairs:", error);
        return [];
    }
}