// import './TransactionLink.css';
import React from 'react';
import { getExplorerTransactionUrl, formatString } from '../helper'

const TRANSACTION_MAX_LENGTH = 10

const TransactionLink = ({ transactionsId }) => {
  const explorerLink = getExplorerTransactionUrl(transactionsId);
  const shortTxid = formatString(transactionsId, TRANSACTION_MAX_LENGTH)

  return (
    <div>
      <a href={explorerLink}> {shortTxid} </a>
    </div>
  );
};

export default TransactionLink;
