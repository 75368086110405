
// export const API_URL = 'http://130.162.227.246:8000';
// export const TOKEN = '9b8d85291a04f880385acc711fd4418ba3a7865e'; // Replace with your actual token


// export const API_URL = 'https://api2.onchainvision.xyz';
// export const TOKEN = '10cd50fba4742a4fbf8c5dea2e1cd98e6b8983cf'; // Replace with your actual token



export const API_URL = 'https://api.onchainvision.xyz';
export const TOKEN = 'd12317791a034e12ce9cc49a9663719308c1c3f6'; // Replace with your actual token



// export const API_URL = process.env.ONCHAINVISION_APP_API_URL || 'https://api2.onchainvision.xyz';
// export const TOKEN = process.env.ONCHAINVISION_APP_TOKEN || 'default_token_value';


export const WEBSITE_VERSION = "0.2.9";

export const SPOT_PAIR_ADDRESS= "TTQpjqQUjMJjF3MAvWWVURn3YrRxg2quTM"
