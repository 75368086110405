
import { API_URL, TOKEN } from './constants';
let cacheSinglePair = new Map();
const cachedPairs = new Map();
let cachedNetworks = null; 
const cachedExchanges = new Map(); 
const cachePairData = new Map();
const cachePairTxns = new Map();

const headers = new Headers({
    'Authorization': `Token ${TOKEN}`,
    'Content-Type': 'application/json',
});

const requestOptions = {
    method: 'GET',
    headers: headers,
};


export const fetchOnChainSinglePair = async (pairAddress) => {
    if (pairAddress === "") {
        console.log("pairAddress == '' (empty)");
        return {}; // Don't fetch if no network is selected, return an empty array
    }

    console.log('fetchOnChainSinglePair: pairAddress' + pairAddress);
    
    if (cacheSinglePair.has(pairAddress)) {
        return cacheSinglePair.get(pairAddress);
    }

    try {
        const apiUrl = `${API_URL}/api/dex/pair/?address=${pairAddress}&forceUpdate=1`;
        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const myPair = data[0]
        console.log('fetchOnChainSinglePair: Pair done', myPair);

        // Cache the fetched data
        cachePairData.set(pairAddress, myPair);
        return myPair;
    } catch (error) {
        console.error('Error fetching pairs:', error);
        return []; // Return an empty array if an error occurs
    }
};

const fetchOnChainPairs = async (network, exchange, token0, token1) => {
    if (network === -1 && exchange === -1 && token0 === -1 && token1 === -1) {
        console.log("network ==  exchange == token0 == token1 == -1");
        return []; // Don't fetch if no network, exchange, token0 is selected, return an empty array
    }

    let api_url = `${API_URL}/api/dex/pair?`

    if(network !== -1 && exchange !== undefined)
    {
        console.log(`fetchOnChainPairs: Fetching Pairs for network=${network}`)
        api_url += `network=${network}&`
    }

    if(exchange !== -1 && exchange !== undefined)
    {
        console.log(`fetchOnChainPairs: Fetching Pairs for exchange=${exchange}`)
        api_url += `exchange=${exchange}&`
    }
    
    if(token0 !== -1 && exchange !== undefined)
    {
        console.log(`fetchOnChainPairs: Fetching Pairs for token0=${token0}`)
        api_url += `token0=${token0}&`
    }
    
    if(token1 !== -1 && exchange !== undefined)
    {
        console.log(`fetchOnChainPairs: Fetching Pairs for token1=${token1}`)
        api_url += `token1=${token1}&`
    }

    // Remove the last '&'
    api_url = api_url.slice(0, -1);
    console.log(`fetchOnChainPairs: api_url=${api_url}`)


    try {
        const response = await fetch(api_url, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Pairs done');
        return data;
    } catch (error) {
        console.error('Error fetching pairs:', error);
        return []; // Return an empty array if an error occurs
    }
};


const fetchOnChainNetworks = async () => {
    console.log('Fetching networks...')
    try {
        const response = await fetch(`${API_URL}/api/dex/networks`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data)
        console.log('Networks done')
        return data;
    } catch (error) {
        console.error('Error fetching networks:', error);
        return []; // Return an empty array if an error occurs
    }
};

export const fetchOnChainTokenID = async (id) => {
    console.log("fetchOnChainNetworkID ", id);
    try {
        const response = await fetch(`${API_URL}/api/dex/token/${id}`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data)
        console.log('fetchOnChainNetworkID done')
        return data;
    } catch (error) {
        console.error('Error fetching networks:', error);
        return []; // Return an empty array if an error occurs
    }
};

export const fetchOnChainNetworkID = async (id) => {
    console.log("fetchOnChainNetworkID ", id);
    try {
        const response = await fetch(`${API_URL}/api/dex/networks/${id}`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data)
        console.log('fetchOnChainNetworkID done')
        return data;
    } catch (error) {
        console.error('Error fetching networks:', error);
        return []; // Return an empty array if an error occurs
    }
};

export const fetchOnChainExchangeId = async (id) => {
    console.log("fetchOnChainExchangeId ", id);

    try {
        const response = await fetch(`${API_URL}/api/dex/exchanges/${id}`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Exchange ID done')
        return data;
    } catch (error) {
        console.error('Error fetching Exchanges:', error);
        return []; // Return an empty array if an error occurs
    }
};

const fetchOnChainExchanges = async (selectedNetwork) => {
    if (selectedNetwork === -1) {
        console.log("fetchOnChainExchanges: selectedNetwork == -1");
        return []; // Return an empty array if no network is selected
    }
    console.log("fetchOnChainExchanges: selectedNetwork", selectedNetwork);

    try {
        const response = await fetch(`${API_URL}/api/dex/exchanges?network=${selectedNetwork}`, requestOptions);
        if (!response.ok) {
            throw new Error('fetchOnChainExchanges: Network response was not ok');
        }
        const data = await response.json();
        console.log('fetchOnChainExchanges: Exchanges done')
        return data;
    } catch (error) {
        console.error('fetchOnChainExchanges: Error fetching Exchanges:', error);
        return []; // Return an empty array if an error occurs
    }
};

export const fetchNetworks = async () => {
    if (!cachedNetworks) {
        // If cached data is not available, fetch and cache it
        cachedNetworks = await fetchOnChainNetworks();
    }
    return cachedNetworks; // Return the cached data
};


export const fetchExchanges = async (selectedNetwork) => {
    const cacheExchangeKey = selectedNetwork.toString();

    if (cachedExchanges.has(cacheExchangeKey)) {
        console.log(`fetchExchanges: Use cache for ${cacheExchangeKey} return ${cachedExchanges.get(cacheExchangeKey)}`);
        return cachedExchanges.get(cacheExchangeKey);
    }

    try {
        // Add 'await' to properly wait for the asynchronous operation to complete
        const data = await fetchOnChainExchanges(selectedNetwork);

        // Assuming fetchOnChainExchanges returns a promise, resolve it to get the actual data
        const resolvedData = await data;

        cachedExchanges.set(cacheExchangeKey, resolvedData);

        return resolvedData;
    } catch (error) {
        console.error(`Error fetching exchanges: ${error}`);
        // Handle the error appropriately (e.g., throw it, return a default value, etc.)
        throw error;
    }
};



export const fetchTokens = async (selectedNetwork) => {
    if (selectedNetwork === -1) {
        console.log("selectedNetwork == -1");
        return []; // Return an empty array if no network is selected
    }
    console.log('Fetching Tokens for network_id: ' + selectedNetwork);
    try {
        const response = await fetch(`${API_URL}/api/dex/token?network=${selectedNetwork}`, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Tokens done');
        return data;
    } catch (error) {
        console.error('Error fetching tokens:', error);
        return []; // Return an empty array if an error occurs
    }
};



export const fetchPairs = async (network, exchange, token) => {
    console.log(`fetchPairs: network=${network}, exchange=${exchange}, token=${token}`);
    const cacheKey = `${network}_${exchange}`;

    if (cachedPairs.has(cacheKey)) {
        console.log(`fetchPairs: Using cache for ${cacheKey}`);
        let cachedResult = cachedPairs.get(cacheKey);

        if (token !== "") {
            // Filter the cached result based on token
            cachedResult = cachedResult.filter(pair => pair.token0 === token || pair.token1 === token);
        }
        return cachedResult;
    }

    let result;
    if (token !== "") {
        const firstArray = await fetchOnChainPairs(network, exchange, token, -1);
        const secondArray = await fetchOnChainPairs(network, exchange, -1, token);
        result = [...firstArray, ...secondArray];
    } else {
        result = await fetchOnChainPairs(network, exchange, -1, -1);
        cachedPairs.set(cacheKey, result);
    }


    // Do something with the 'result', for example, return it
    return result;
};


export const fetchPairTxns = async (selectedPair ) => {
    if (!selectedPair) return; // Don't fetch if no pair is selected
    console.log('Fetching PairTxns for pair address: ' + selectedPair);

    // Check if the data is in the cache and return it// Check if the data is in the cache and return it
    console.log("fetchPairTxns: cacheKey", selectedPair)
    if (cachePairTxns.has(selectedPair)) {
        return cachePairTxns.get(selectedPair);
    }

    try {
        const apiUrl = `${API_URL}/api/dex/pairTxns/?address=${selectedPair}`;
        console.log("fetchPairTxns: apiUrl", apiUrl)

        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data)
        const data200 = data.slice(0,200)
        console.log(data200)

        const cdata = data200.map((d) => {

            const my_date = new Date(d.timestamp)
        
            const mappedData = {
                time: my_date, // Convert the timestamp to a Date object
                txId: d.txId,
                receiver: d.addressReceived,
                sender: d.addressSent,
                valueToken0: parseFloat(d.valueToken0),
                valueToken1: parseFloat(d.valueToken1),
                buyToken0: d.buyToken0
            };
    
        
            return mappedData;
        });
        
        console.log('Fetch pair txns done');
        console.log(cdata);

        // Cache the fetched data
        cachePairTxns.set(selectedPair, cdata);

        return cdata

    } catch (error) {
        console.error('Error fetching pair data:', error);
    }
};



export const fetchPairData = async (selectedPair, timeRange, reversed, force_update = 0) => {
    if (!selectedPair) return; // Don't fetch if no pair is selected
    console.log('fetchPairData: Fetching PairData for pair address: ' + selectedPair);
    console.log('fetchPairData: timeRange: ' + timeRange);
    console.log('fetchPairData: reversed: ' + reversed);

    // Check if the data is in the cache and return it// Check if the data is in the cache and return it
    const cacheKey = reversed ? `${selectedPair}_${timeRange}_reversed` : `${selectedPair}_${timeRange}`;
    console.log("fetchPairData: cacheKey", cacheKey)
    if (cachePairData.has(cacheKey)) {
        return cachePairData.get(cacheKey);
    }

    const apiUrl = `${API_URL}/api/dex/pairAudit/?address=${selectedPair}${force_update ? '&forceUpdate=1' : ''}&timeRange=${timeRange.toLowerCase()}${reversed ? '&reversed=true' : ''}`;

    try {
        console.log("fetchPairData: apiUrl", apiUrl)

        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log("fetchPairData response ok", data)
        // console.log(data)

        const cdata = data.map((d) => {

            const my_date = new Date(d.timestamp)
        
            const mappedData = {
                time: my_date, // Convert the timestamp to a Date object
                open: parseFloat(d.open),
                high: parseFloat(d.high),
                low: parseFloat(d.low),
                close: parseFloat(d.close),
                volume: parseFloat(d.volumeTokenB),
            };
    
        
            return mappedData;
        });
        
        cdata.sort((a, b) => a.time.getTime() - b.time.getTime()); // Sort based on timestamps
        
        console.log('Fetch pair data done');
        console.log(cdata);

        // Cache the fetched data
        cachePairData.set(cacheKey, cdata);

        return cdata

    } catch (error) {
        console.error('Error fetching pair data:', error);
    }
};

