// Section.jsx
import './Section.css'

function Section({ title, content }) {
  return (
    <section className="section">
      <div className="box-main">
        <div className="content">
          <h1 className="text-big">{title}</h1>
          <p className="text-small">{content}</p>
        </div>
      </div>
    </section>
  );
}

export default Section;
