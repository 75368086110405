import { useState, useEffect, useRef } from 'react';
import { fetchNetworks, fetchExchanges, fetchTokens, fetchPairs, fetchPairData } from '../api/onchain_api';
import { createChart } from 'lightweight-charts';
import NetworkCarousel from './networkList/NetworkCarousel';
import ExchangeCarousel from './dexList/ExchangeCarousel';
import TokenCarousel from './tokensList/TokenCarousel';
import PairPage from './pairList/PairPage';
import './Pairs.css'

function Pairs() {
  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(-1);
  const [exchanges, setExchanges] = useState([]);
  const [selectedExchange, setSelectedExchange] = useState(-1);
  const [tokens, setTokens] = useState([]);
  const [selectedToken, setSelectedToken] = useState(-1);
  const [filterPairs, setFilterPairs] = useState([]);
  const [pairs, setPairs] = useState([]);
  const [selectedPair, setSelectedPair] = useState("");
  const [isLoadingNetworks, setIsLoadingNetworks] = useState(false);
  const [isLoadingExchanges, setIsLoadingExchanges] = useState(false);
  const [isLoadingTokens, setIsLoadingTokens] = useState(false);
  const [isLoadingPairs, setIsLoadingPairs] = useState(false);
  const [isLoadingPairData, setIsLoadingPairData] = useState(false);
  const chartContainerRef = useRef(null);
  const candleSeriesRef = useRef(null);

  const fetchNetworksTrigger = async () => {
    try {
      const ret_networks = await fetchNetworks();
      console.log("Networks response received from API")
      console.log(ret_networks)
      setNetworks(ret_networks);
      setSelectedNetwork(1); // Set selected network to ID 1 by default
      await fetchExchangesTrigger(1)
      await fetchTokensTrigger(1)
      await fetchPairsTrigger(1, -1, -1)
    } catch (error) {
      console.error('Error fetching networks:', error);
      // Handle error if needed
    }
  };

  const fetchExchangesTrigger = async (idNetwork) => {
    try {
      const my_exchanges = await fetchExchanges(idNetwork);
      console.log('fetchExchangesTrigger: my_exchanges=', my_exchanges);
      setExchanges(my_exchanges);
    } catch (error) {
      console.error('Error handling exchange change:', error);
      // Handle errors if needed
    } finally {
      setIsLoadingExchanges(false);
    }
  };

  const fetchTokensTrigger = async (idNetwork) => {
    try {
      const my_tokens = await fetchTokens(idNetwork);
      console.log('fetchTokensTrigger: my_tokens=', my_tokens);
      setTokens(my_tokens);
    } catch (error) {
      console.error('Error handling exchange change:', error);
      // Handle errors if needed
    } finally {
      setIsLoadingExchanges(false);
    }
  };

  const fetchPairsTrigger = async (network, exchange, token = "") => {
    console.log(`fetchPairsTrigger: network=${network}, exchange=${exchange}, token=${token}`);
    try {
      const my_pairs = await fetchPairs(network, exchange, token);
      console.log('fetchPairsTrigger: my_pairs=', my_pairs);
      setPairs(my_pairs);
      setFilterPairs(my_pairs);
    } catch (error) {
      console.error('Error handling pair update:', error);
      // Handle errors if needed
    } finally {
      setIsLoadingExchanges(false);
    }
  };

  const handleNetworkChange = async (newSelectedId) => {
    console.log(`handleNetworkChange: newSelectedId=${newSelectedId}`);
    setIsLoadingExchanges(true);
    setSelectedNetwork(newSelectedId);
    setPairs([])
    setFilterPairs([])
    await fetchExchangesTrigger(newSelectedId);  // Use await here as well
    await fetchTokensTrigger(newSelectedId)
    await fetchPairsTrigger(newSelectedId, -1, -1)
    console.log("handleNetworkChange: exchanges=", exchanges)
    console.log("handleNetworkChange: exchanges=", tokens)
  };

  const handleExchangeChange = async (newSelectedId) => {
    console.log(`handleExchangeChange: newSelectedId=${newSelectedId}`);
    setSelectedExchange(newSelectedId); // Update selected token on change
    setSelectedToken(-1); // Unselect token
    // await fetchPairsTrigger(selectedNetwork, newSelectedId); // Fetch pairs when a token is selected
    const filteredPairs = newSelectedId === -1 ? pairs : pairs.filter((pair) => {
      return pair.exchange === newSelectedId;
    });
    setFilterPairs(filteredPairs);
    console.log("handleExchangeChange: filteredPairs=", pairs)
    console.log("handleExchangeChange: filteredPairs=", filteredPairs)
  };

  const handleTokenChange = (newSelectedId) => {
    console.log(`handleTokenChange: newSelectedId=${newSelectedId}`);
    setSelectedToken(newSelectedId); // Update selected token on change
  
    // If both selected token and exchange are -1, show all pairs
    if (newSelectedId === -1 && selectedExchange === -1) {
      setFilterPairs(pairs);
      return;
    }
  
    // If only selected token is -1, filter by exchange
    if (newSelectedId === -1) {
      const filteredPairsByExchange = pairs.filter((pair) => pair.exchange === selectedExchange);
      setFilterPairs(filteredPairsByExchange);
      return;
    }
  
    // If only selected exchange is -1, filter by token
    if (selectedExchange === -1) {
      const filteredPairsByToken = pairs.filter((pair) => pair.tokenA === newSelectedId || pair.tokenB === newSelectedId);
      setFilterPairs(filteredPairsByToken);
      return;
    }
  
    // Filter pairs by both selected token and exchange
    const filteredPairsByExchange = filteredPairs.filter((pair) => pair.exchange === selectedExchange);
    const filteredPairs = filteredPairsByExchange.filter((pair) => pair.tokenA === newSelectedId || pair.tokenB === newSelectedId);
    setFilterPairs(filteredPairs);
  };
  

  useEffect(() => {
    fetchNetworksTrigger();
  }, []);

  useEffect(() => {
    if (selectedPair) {
      const fetchPairDataTrigger = async () => {
        try {
          const mydata = await fetchPairData(selectedPair);
          console.log('Pair Data received')
          console.log(mydata)
          if (chartContainerRef.current && !candleSeriesRef.current) {
            const chartProperties = {
              width: window.innerWidth - 50,
              height: window.innerHeight > 768 ? 600 : 400,
              timeScale: {
                timeVisible: true,
                secondsVisible: false,
              },
            };
            const chart = createChart(chartContainerRef.current, chartProperties);
            const candleSeries = chart.addCandlestickSeries();
            candleSeries.setData(mydata);
            candleSeriesRef.current = candleSeries;
          } else if (candleSeriesRef.current) {
            candleSeriesRef.current.setData(mydata);
          }
          console.log('PairData done');
        } catch (error) {
          console.error('Error fetching networks:', error);
          // Handle error if needed
        }
      }

      fetchPairDataTrigger();
    }
    else {
      console.log('Unknown address: ' + selectedPair)
    }
  }, [selectedPair]);

  return (
    <div className="pair-list">
      {isLoadingNetworks ? <div>Loading Networks...</div>
        :
        <div className='network-selection'>
          <small>Network</small>
          <NetworkCarousel networkArray={networks} selectedId={selectedNetwork} onSelect={handleNetworkChange} />
        </div>
      }
      {selectedNetwork && !isLoadingExchanges ?
        (
          <div className='exchange-selection'>
          <small>Filter Exchange</small>
          <ExchangeCarousel exchangeArray={exchanges} selectedId={selectedExchange} onSelect={handleExchangeChange} />
        </div>
        )
        :
        (
          <div>Loading Exchanges...</div>
        )
      }

      {selectedNetwork && !isLoadingTokens ?
        (
          <div className='pair-selection'>
          <small>Filter Tokens</small>
          <TokenCarousel tokenArray={tokens} selectedId={selectedToken} onSelect={handleTokenChange} />
        </div>
        )
        :
        (
          <div>Loading Tokens...</div>
        )
      }

      {selectedNetwork && !isLoadingPairs && (
        <div>
        <PairPage pairs={filterPairs}  />
        </div>
      )}

      {isLoadingPairs && <div>Loading Pairs...</div>}
      {isLoadingPairData && <div>Loading Pair Data...</div>}

      <div id="onchainvisioncharts" className="chart-container" ref={chartContainerRef}></div>
    </div>
  );
}

export default Pairs;
