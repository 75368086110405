import React, { useState } from 'react';
import './PairList.css'; // Import the CSS file

const PairList = ({ pairList, currentPage, itemsPerPage }) => {
  console.log(`PairList: pairList.lenght=${pairList.length}, currentPage=${currentPage}, itemsPerPage=${itemsPerPage}`)
  const indexOfLastPair = currentPage * itemsPerPage;
  const indexOfFirstPair = indexOfLastPair - itemsPerPage;

  const currentPairs = pairList.slice(indexOfFirstPair, indexOfLastPair);

  return (
    <div className="pair-list-container">
      <h2>Pair List</h2>
      <table className="pair-table">
        <thead>
          <tr>
            <th>Pair ID</th>
            <th>Charts</th>
            <th>Charts Reversed</th>
            <th>Symbol</th>
            <th>Address</th>
            <th>TokenA Balance</th>
            <th>TokenB Balance</th>
          </tr>
        </thead>
        <tbody>
          {currentPairs.map((pair) => {
            let exchange = pair.symbol.split('-')[0];
            let token0 = pair.symbol.split('-')[1];
            let token1 = pair.symbol.split('-')[2];
            return (
              <tr key={pair.pairId}>
                <td>{pair.pairId}</td>
                <td>📈  <a href={`/spot/${pair.address}`}>{token0}/{token1}</a></td>
                <td>📉  <a href={`/spot/${pair.address}_r`}>{token1}/{token0}</a></td>
                <td>{pair.symbol}</td>
                <td>{pair.address}</td>
                <td>{pair.tokenABalance}</td>
                <td>{pair.tokenBBalance}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PairList;
