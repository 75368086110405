

// Makes requests to CryptoCompare API
export async function makeApiRequest(path) {
    try {
        const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
        return response.json();
    } catch (error) {
        throw new Error(`CryptoCompare request error: ${error.status}`);
    }
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
    const short = `${fromSymbol}/${toSymbol}`;
    return {
        short,
        full: `${exchange}:${short}`,
    };
}

// Convert date to UNIX
export function convertDateToUtcUnix(dateString) {
    console.log(`convertDateToUtcUnix: Input string ${dateString}`)
    const utcDate = new Date(dateString);


    // Check if the date is valid
    if (!isNaN(utcDate.getTime())) {
        return Math.floor(utcDate.getTime() / 1000); // Convert milliseconds to seconds for Unix timestamp
    }

    console.error('Invalid date format');
    return -1; // Return -1 in case of an error
}


export function computeExponent(number) {
    if (number === 0.0) {
        return 0; // Any number to the power of 0 is 1
    }

    let exponent = 0;
    let num = number; // No need to convert to double in JavaScript

    while (num >= 10.0 || num <= -10.0) {
        num /= 10.0;
        exponent++;
    }

    if (exponent > 0) {
        return exponent++;
    }

    while (num < 1.0 && num > -1.0) {
        num *= 10.0;
        exponent--;
    }

    return exponent;
}

export function computePriceScale(price) {

    console.log('computePriceScale: price:', price);

    // Compute the exponent of the price
    const exponent = computeExponent(price);
    console.log("computePriceScale:  computeExponent:", exponent);


    // Add a six digit precision
    const computedPrecision = exponent - 6;

    // Compute minMove based on the final precision
    const finalPriceScale = Math.pow(10, -computedPrecision);

    // minMove
    const finalMinMove = 1

    console.log("computePriceScale:  PriceScale:", finalPriceScale);
    console.log("computePriceScale:  MinMove:", finalMinMove);

    return { finalPriceScale, finalMinMove }
}

export function getExplorerTransactionUrl(txId) {
    console.log('computePriceScale: txId:', txId);

    const linkAddress = `https://tronscan.org/#/transaction/${txId}`
    console.log('computePriceScale: linkAddress:', linkAddress);

    return linkAddress
}

export function getExplorerAddressUrl(address) {
    console.log('computePriceScale: txId:', address);

    const linkAddress = `https://tronscan.org/#/address/${address}`
    console.log('computePriceScale: linkAddress:', linkAddress);

    return linkAddress
}

export function formatString(stringInput, length = 20) {
    // Ensure the length is within a valid range
    const validLength = Math.max(5, Math.min(length, stringInput.length - 5));

    // Extract the first x characters and last 5 characters
    const truncatedID = stringInput.substring(0, validLength - 3) + '...' + stringInput.slice(-5);

    return truncatedID;
}

export function formatDate(date) {
    const options = { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate.replace(',', ''); // Remove the comma after the day
};