// TokenInfo.js
import React from 'react';
import './TokenInfo.css';  // Import the CSS file for styling

const TokenInfo = ({ token, selectedId}) => {
  const isSelected = selectedId === token.id;

  return (
    <div className={`token-info ${isSelected ? 'selected' : ''}`}>
      <img src = {token.logo} width={'25px'} height={'25px'} />
      <p>{token.symbol}</p>
    </div>
  );
};

export default TokenInfo;
