// import './AccountLink.css';
import React from 'react';
import { getExplorerAddressUrl, formatString } from '../helper'

const ACCOUNT_MAX_LENGTH = 10

const AccountLink = ({ accountAddress }) => {
  const explorerLink = getExplorerAddressUrl(accountAddress);
  const shortAddress = formatString(accountAddress, ACCOUNT_MAX_LENGTH)

  return (
    <div>
      <a href={explorerLink}> {shortAddress} </a>
    </div>
  );
};

export default AccountLink;
