// Footer.jsx
function Footer() {
  return (
    <footer className="footer">
      <p className="text-footer">
        Copyright ©-All rights are reserved
      </p>
    </footer>
  );
}

export default Footer;