// TokenCarousel.js
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import TokenInfo from './TokenInfo';
import './TokenCarousel.css';  // Import the CSS file for styling
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TokenCarousel = ({ tokenArray, selectedId, onSelect }) => {
  
  if(!tokenArray.length) {
    return (
      <div className="no-item-carousel">
        No Tokens for the selected Network
      </div>
    )
  }

  const handleItemClick = (index) => {
    if(selectedId === index)
    {
      console.log("ExchangeCarousel: selectedId === index")
      onSelect(-1);
    }
    else{
      onSelect(index);
    }
  };

  const tokenWhitelistedArray = tokenArray.filter(token => token.whitelist === "Y");


  const settings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    speed: 500,
    centerPadding: "60px",
    variableWidth: true,
    slidesToShow: tokenWhitelistedArray.length,
    slidesToScroll: 1,
    afterChange: (index) => {
      // Update internalSelectedId after each change in the carousel
      const newSelectedId = tokenWhitelistedArray[index]?.id || null;
      // Notify the parent component about the change
      onSelect(newSelectedId);
    },
  };
  

  return (
    <div className="token-carousel-container">
      <Slider {...settings}>
        {tokenWhitelistedArray.map((token) => (
          <div key={token.id} onClick={() => handleItemClick(token.id)}>
            <TokenInfo key={token.id} token={token} selectedId={selectedId} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TokenCarousel;
