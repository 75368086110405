
import Section from '../section/Section';
import './Home.css'

// Home.jsx
function Home() {
    return (
		<div className="sections-container">
			<Section
        		title="Test Instructions"
        		content="Download the App on Play Store, Use a wallet with WalletConnectv2 TRust Wallet, Open the OnChain Vision app, Chose the Network to connect to, Scan the QR /Copy the link/ Open wallet local on the phone through deeplinks, Confirm in the wallet the connection"
      		/>

			<Section
				title="Why mobile development ?"
				content="Focusing days in creating something that would bring the most value to the community. I ask even the community and receive feedback from you guys (very grateful for those who helped me out). As you might know there are a lot of projects web based (some of them supports mobile browsing) but very little to none around mobile. So the best value that I could bring is develop around mobile"
			/>

			<Section
        		title="More about WalletConnect v2… What should I know and why?"
        		content="Well right now WalletConnect is in a migrated process from v1 to v2 which will end by end of June. This is important because a Wallet with v1 cannot connect to a dApp with v2. SO by the end of the June all the Wallets will migrate to v2."
      		/>

			<Section
				title="Best Solution"
				content="Well the best solution depends on what do you need but in my case as I see the things you should have on one side the wallet and on the other the app or dApp that should connect to your Wallet. The single tech solution that I found that does this perfect is WalletConnect. WalletConnect Provides a solution to connect a wallet to and dApp using web socket."
			/>
      </div>
    );
  }
  
  export default Home;
  