
import AccountLink from './AccountLink';
import TransactionLink from './TransactionLink';
import './TransactionList.css'
import React from 'react';
import { formatDate } from '../helper'

const TransactionList = ({ transactions }) => {
    return (
        <div className={'PairTxns'}>
            <div className="transaction-list-container">
                <div className="transaction-list-header">
                    <div className="column">Transaction</div>
                    <div className="column">Price</div>
                    <div className="column">Type</div>
                    <div className="column">Time</div>
                    <div className="column">Amount A</div>
                    <div className="column">Amount B</div>
                    <div className="column">Sender</div>
                    <div className="column">Receiver</div>
                </div>
                <div className="transaction-list">
                    {transactions.map((transaction, index) => (
                        <div key={index} className="transaction-item">
                            <div className="column"> <TransactionLink  transactionsId={transaction.txId}/> </div>
                            <div className="column" style={{ color: transaction.buyToken0 ? 'red' : 'green' }}>{transaction.valueToken1/transaction.valueToken0}</div>
                            <div className="column" style={{ color: transaction.buyToken0 ? 'red' : 'green' }}>{transaction.buyToken0 ? 'Sell' : 'Buy'}</div>
                            <div className="column">{formatDate(transaction.time)}</div>
                            <div className="column" style={{ color: transaction.buyToken0 ? 'red' : 'green' }}>{transaction.valueToken0}</div>
                            <div className="column" style={{ color: transaction.buyToken0 ? 'red' : 'green' }}>{transaction.valueToken1}</div>
                            <div className="column"> <AccountLink  accountAddress={transaction.sender}/> </div>
                            <div className="column"> <AccountLink  accountAddress={transaction.receiver}/> </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TransactionList;