// ExchangeInfo.js
import React from 'react';
import './ExchangeInfo.css';  // Import the CSS file for styling

const ExchangeInfo = ({ exchange, selectedId }) => {
  const isSelected = selectedId === exchange.id;

  return (
    <div className={`exchange-info  ${isSelected ? 'selected' : ''}`}>
      <img className="exchange-logo" src={exchange.logo} alt={exchange.name} />
      <a href={exchange.website} target="_blank" rel="noopener noreferrer">
        <p>{exchange.name}</p>
      </a>
    </div>
  );
};

export default ExchangeInfo;
