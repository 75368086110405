// Nav.jsx

import React, { useState } from 'react';
import logo from '../static/logo.svg';
import GooglePlay from '../static/GooglePlay.svg';
import './Nav.css'; // Import or create a separate CSS file for styling
import { WEBSITE_VERSION } from '../api/constants'

function Nav() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div>
      {/* Beta information */}
      <div className="beta-info">
        <p>This is {WEBSITE_VERSION} beta version. Your feedback is valuable! Provide it on <a href='https://discord.gg/3ZPy6tCv4y'>Discord</a>!</p>
      </div>

      <nav className={`navbar background ${showMenu ? 'show-menu' : ''}`}>
        <div className="logo">
          <a href='/'><img src={logo} alt="Logo" /></a>
        </div>
        <button className="mobile-menu-button" onClick={toggleMenu}>
          &#9776;
        </button>
        <ul className={`nav-list ${showMenu ? 'show' : ''}`}>
          <li><a href='/pairs'>Pairs</a></li>
          <li><a href='/spot'>Charts</a></li>
          <li><a href='https://discord.gg/3ZPy6tCv4y'>Discord</a></li>
          <li><a href='https://forum.trondao.org/t/onchain-vision-open-your-eyes-on-chain-world/17293'>ForumTron</a></li>
        </ul>
        <div className={`rightNav ${showMenu ? 'show' : ''}`}>
          <a href='https://play.google.com/store/apps/details?id=com.onchainvision.onchainvision'><img src={GooglePlay} height="150px" alt="Google Play" /></a>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
