
import Section from '../section/Section';

// About.jsx
function About() {
    return (
        <div>
			<Section
        		title="About"
        		content="OnChainVision is a dApp that brings to the users all on chain data"
      		/>
			
      </div>
    );
  }
  
  export default About;
  